<template>
  <main>
    <h1>Compte Pandaloc: {{ paymentApp.uid }}</h1>
    <div>
      <h3>Identifiant du compte Swan</h3>
      <p>{{ paymentApp.swanAccount }}</p>
    </div>
    <div>
      <h3>Statut du compte Swan</h3>
      <p>{{ paymentApp.swanAccountStatus }}</p>
    </div>
    <div>
      <h3>IBAN du compte Swan</h3>
      <p>{{ paymentApp.swanIban }}</p>
    </div>
    <!-- <div>
      <h3>Type</h3>
      <p>{{ paymentApp.type.label }}</p>
    </div> -->
    <div>
      <h3>Titulaire du compte</h3>
      <p>{{ paymentApp.name }}</p>
    </div>
    <div>
      <h3>Rémunération mensuelle nette</h3>
      <p>{{ paymentApp.monthlyIncome }}</p>
    </div>
    <div v-if="paymentApp.addressProofType">
      <h3>Type de justificatif de domicile</h3>
      <p>{{ paymentApp.addressProofType.label }}</p>

      <a target="blank" :href="BaseUrl + paymentApp.addressProofUrl">{{BaseUrl + paymentApp.addressProofUrl}}</a>
    </div>
    <div v-if="paymentApp.kbisUrl">
      <h3>URL privée du KBIS</h3>
      <a target="blank" :href="BaseUrl + paymentApp.kbisUrl">{{BaseUrl + paymentApp.kbisUrl}}</a>
    </div>
    <div v-if="paymentApp.statutesUrl">
      <h3>URL privée des statuts</h3>
      <a target="blank" :href="BaseUrl + paymentApp.statutesUrl">{{BaseUrl + paymentApp.statutesUrl}}</a>
    </div>
    <div>
      <h3>IBAN personnel de l'utilisateur</h3>
      <p>{{ paymentApp.iban }}</p>
    </div>
    <div>
      <h3>Solde du compte</h3>
      <p>{{ paymentApp.balance }}</p>
    </div>
    <div>
      <h3>Réserve tampon transférée à Pandaloc</h3>
      <p>{{ paymentApp.transferredReserve }}</p>
    </div>
    <div>
      <h3>Dernière date d'authentification forte</h3>
      <p>{{ paymentApp.authDate }}</p>
    </div>
    <div>
      <h3>Status</h3>
      <p>{{ paymentApp.status }}</p>
    </div>
    <div>
      <h3>Date de création</h3>
      <p>{{ paymentApp.creationDate }}</p>
    </div>
    <div>
      <h3>Date de mise en attente de validation chez Swan</h3>
      <p>{{ paymentApp.pendingDate }}</p>
    </div>
    <div>
      <h3>Date d'activation</h3>
      <p>{{ paymentApp.activationDate }}</p>
    </div>
  </main>
</template>

<script>
import axios from 'axios'

export default {
  data: () => ({
    BaseUrl: process.env.VUE_APP_BASEURL,
    paymentApp: {},
  }),
  mounted() {
    axios
      .get(
        process.env.VUE_APP_APIURL +
          "/paymentApps/" +
          this.$route.params.paymentAppUid,
        {
          headers: {
            "Access-Token": localStorage.getItem("token"),
          },
          params: {
            // expand: 'type,addressProofType'
            expand: 'addressProofType,flows'
          }
        }
      )
      .then((res) => {
        this.paymentApp = Object.assign(
          res.data,
          {
          status: 
            {
              1: "1 (brouillon)",
              2: "2 (en attente de validation Swan)",
              3: "3 (actif)",
            }[res.data.status],
            authDate: new Date(res.data.authDate).toLocaleString(),
            creationDate: new Date(res.data.creationDate).toLocaleString(),
            pendingDate: new Date(res.data.pendingDate).toLocaleString(),
            activationDate: new Date(res.data.activationDate).toLocaleString(),
          },
        );
      });
  }
}
</script>

<style>
  main {
    max-width: 600px;
    margin: auto;
  }
</style>